var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "container" }, [
      _c(
        "div",
        { staticClass: "menu-nav" },
        _vm._l(_vm.menu.menuSections, function(section) {
          return _c(
            "a",
            {
              key: section.id,
              class: { active: _vm.selectedItem.id == section.id },
              attrs: { href: section.id },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.selectItem(section.id)
                }
              }
            },
            [
              _vm._v(
                "\n            " + _vm._s(section.title) + "\n            "
              )
            ]
          )
        }),
        0
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "menu-sections" }, [
      _c("div", { staticClass: "section menu-section" }, [
        _c("div", { staticClass: "image-slider" }, [
          _c(
            "div",
            { staticClass: "section-slider-wrap" },
            [
              _c(
                "carousel",
                {
                  attrs: {
                    perPage: 1,
                    perPageCustom: [[1024, 3]],
                    "center-mode": true,
                    autoplay: true,
                    "autoplay-timeout": 5000,
                    loop: true,
                    speed: 2500
                  }
                },
                _vm._l(_vm.selectedItem.images, function(img) {
                  return _c("slide", { key: img.itemDescription }, [
                    _c("img", { staticClass: "img", attrs: { src: img.url } })
                  ])
                }),
                1
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _vm.selectedItem.subtitle
          ? _c("div", { staticClass: "containter subtitle" }, [
              _c("h5", {
                staticStyle: {
                  "text-align": "center",
                  "margin-top": "1rem",
                  "font-size": "1.5rem"
                },
                domProps: { innerHTML: _vm._s(_vm.selectedItem.subtitle) }
              })
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "menu-content container" },
          _vm._l(_vm.selectedItem.items, function(item) {
            return _c("div", { key: item.name, staticClass: "menu-item" }, [
              _c("h4", [_vm._v(_vm._s(item.name))]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(item.description))]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "item-prices" },
                _vm._l(item.prices, function(row, index) {
                  return _c("div", { key: index, staticClass: "item-price" }, [
                    _vm._v(_vm._s(row.option) + " " + _vm._s(row.price))
                  ])
                }),
                0
              )
            ])
          }),
          0
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }