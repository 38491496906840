<template>
    <div>
        
        <div class="menu-nav" v-if="!hideNav">
            <a :href="type.id" v-for="type in eventTypes" :key="type.id" @click.prevent="selectType(type.id)" v-bind:class="{'active': selectedType == type.id}">
            {{ type.title }}
            </a>
        </div>

        
       
        <div class="events">
            <div class="date-filter">
                
                <v-select :options="dateOptions" v-model="selectedMonth" :clearable="false">
                    <template #open-indicator="{ attributes}">
                        <span v-bind="attributes"><img src="/img/selector.png"></span>
                    </template>
                </v-select>
                   
            </div>
            <div class="no-events" v-if="!eventsToDisplay.length">
                <h4>No events for the selected date range and type.</h4>
            </div>
            <div class="event" v-for="event in eventsToDisplay" :key="event.date">
                <div class="event-image">
                    <img :src="event.img" alt="">
                    <p v-html="getDateDisplay(event.date)"></p>
                </div>
                <div class="event-info">
                    <h4>{{ event.title }}</h4>
                    <p><strong>Date: </strong><span v-html="getDateDisplay(event)"></span></p>
                    <p><strong>Time: </strong><span v-html="getTimeDisplay(event)"></span></p>
                    <p><strong>Event Type: </strong><span v-html="getEventTypesDisplay(event)"></span></p>

                    <div v-html="event.description"></div>
                    <p v-if="event.cta.value"><a :href="event.ctaLink" :target="event.cta.target" class="button">{{ event.cta.customText }}</a></p>
                </div>
                
                
            </div>
        </div>
    </div>
</template>
<script>
    import axios from 'axios';
    import moment from 'moment';
    import lodash from 'lodash';
    import vSelect from 'vue-select';
    export default {
        props: ['calendarId', 'eventType', 'hideNav'],
        components: {
            vSelect
        },
        data() {
            return {
                events: [],
                eventTypes: [],
                selectedType: null,
                selectedMonth: '',
                dateOptions: [],
                attributes: {
                    'ref': 'openIndicator',
                    'role': 'presentation',
                    'class': 'vs__open-indicator',
                }
            }
        },
        mounted() {
            this.getEventTypes();
            this.getEvents();
            this.getDateOptions();
        },
        methods: {
            getEventTypes: function() {
                this.selectedType = this.eventType;
                axios
                    .get('api/event-types.json')
                    .then(
                        (response) => {
                            // console.log(response.data.data);
                            let typesList = response.data.data;
                            let allOption = [{id: 0, title: "All"}];
                            this.eventTypes = allOption.concat(typesList);
                            if(!this.eventType) {
                                this.selectedType = this.eventTypes[0].id;
                            }
                            
                        }
                    )
                    .catch(error => console.log(error));
                
            },
            getEvents: function() {
                axios
                    .get('api/events/'+this.calendarId+'.json')
                    .then(
                        (response) => {
                            // console.log(response.data.data);
                            this.events = response.data.data;
                        }
                    )
                    .catch(error => console.log(error))
            },
            getDateOptions: function() {
                this.selectedMonth = moment().format('MMMM YYYY');
                for (let i = 0; i < 7; i++) {
                    this.dateOptions.push(moment().add(i, 'months').format('MMMM YYYY'));
                }
            },
            getDateDisplay: function(event) {
               
                var start = event.date ? moment(event.date).format('MMM D, YYYY') : null;
                var end = event.endDate ? moment(event.endDate).format('MMM D, YYYY') : null;

                var dateDisplay = '';
                if(end != start) {
                    dateDisplay = start + ' to ' + end;
                } else {
                    dateDisplay = start;
                }

                return dateDisplay;
            },
            getTimeDisplay: function(event) {
                var time = '';
                if(event.allday == '1') {
                    time = 'Open to Close';
                } else {
                    time = moment.utc(event.date).format('h:mm A');
                }
                return time;
            },
            getMonthDisplay: function(date) {
                return moment(date).format('MMM YYYY');
            },
            getEventTypesDisplay: function(event) {
                return _.map(event.eventTypes, 'title').join(', ');
            },
            selectType: function(id) {
                this.selectedType = id;
            },
            filterByType: function(event) {
                return !this.selectedType || _.find(event.eventTypes, {'id':this.selectedType});
            },
            filterByDate: function(event) {
                let eventMonth = moment(event.date).format('MMMM YYYY');
                return !this.selectedMonth || eventMonth == this.selectedMonth;
            }
        },
        computed: {
            eventsToDisplay: function() {
                let vm = this;
                return this.events.filter(this.filterByType).filter(this.filterByDate);
                // return _.filter(vm.events, function(event) {
                //     return event.eventTypes.includes(vm.selectedType);
                // });
            }
        }
    }
</script>

<style>

</style>