<template>
    <div>
        <div class="container" >
            <div class="menu-nav">
                <a :href="section.id" v-for="section in menu.menuSections" :key="section.id" @click.prevent="selectItem(section.id)" v-bind:class="{'active': selectedItem.id == section.id}">
                {{ section.title }}
                </a>
            </div>
        </div>
        <div class="menu-sections">
            <div class="section menu-section parties">
                <div class="image-slider">
                    <div class="section-slider-wrap">
                        <carousel :per-page="3" :center-mode="true" :speed="1000">
                            <slide v-for="img in selectedItem.images" :key="img.itemDescription">
                                <img class="img" :src="img.url" />
                            </slide>
                        </carousel>
                    </div>
                </div>
                
                <div class="menu-content container">
                    <div class="menu-item" v-for="item in selectedItem.items" :key="item.name">
                        <h4>{{ item.name }}</h4>
                        <div class="item-description" v-html="item.description"></div>
                        <div class="item-prices">
                            <div class="item-price">{{ item.price }}</div>
                        </div>
                        <div class="item-cta" v-if="item.cta.value">
                            <a :href="item.cta.value" :target="item.cta.target" v-html="item.cta.customText ? item.cta.customText : 'Book Now'" class="button"></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import moment from 'moment';
    import lodash from 'lodash';
    import { Carousel, Slide } from 'vue-carousel';
    export default {
        components: {
            Carousel,
            Slide
        },
        props: ['menuId'],
        data() {
            return {
                menu: [],
                selectedItem: ''
            }
        },
        mounted() {
            this.getMenu();
        },
        methods: {
            getMenu: function() {
                axios
                    .get('/api/party-menus/'+this.menuId+'.json')
                    .then(
                        (response) => {
                            // console.log(response.data.data);
                            this.menu = response.data.data[0];
                            this.selectedItem = response.data.data[0].menuSections[0];
                        }
                    )
                    .catch(error => console.log(error))
            },
            selectItem: function(id) {
                this.selectedItem = this.menu.menuSections.find(section => section.id == id);
            }
        }
    }
</script>

<style>

</style>