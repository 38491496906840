import Showtimes from './components/Showtimes';
import MenuTabs from './components/MenuTabs';
import PartyMenuTabs from './components/PartyMenuTabs';
import Events from './components/Events';

Vue.component('movie-showtimes', Showtimes);
Vue.component('menu-tabs', MenuTabs);
Vue.component('party-menu-tabs', PartyMenuTabs);
Vue.component('events', Events);

var app = new Vue({
	el: "#app",
    data: {}
});