<template>
    <div>
        <div class="showtimes-row" v-cloak>
           
                <h2>Showtimes</h2>
                <div class="showtimes-date" v-if="selectedDate">
                    <h3 v-html="formatShowdate(selectedDate)"></h3>
                    <p v-for="showtime in showtimesToDisplay" :key="showtime.linkId" >
                        <a v-if="pcId" :href="'https://ticket.schulmantheatres.com/#/screen/' + showtime.pcShowId + '?cinemaId=' +  pcId" v-html="formatShowtime(showtime)" target="_blank"></a>
                        <a v-else :href="rtsUrl + ':2235/T.ASP?WCI=BT&Page=PickTickets&SHOWID=' + showtime.linkId" v-html="formatShowtime(showtime)" target="_blank"></a>
                    </p>
                    <a @click="prevDate()" class="prev" v-if="showPrevArrow"><img src="/img/chevron-left.png" alt="Prev"></a>
                    <a @click="nextDate()" class="next" v-if="showNextArrow"><img src="/img/chevron-right.png" alt="Next"></a>
                </div>
                <div class="showtimes-date" v-else>
                    <h3>No showtimes to display.</h3>
                </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import moment from 'moment';
    import lodash from 'lodash';
    export default {
        props: ['movieId', 'locationId', 'rtsUrl', 'pcId', 'showDate'],
        data() {
            return {
                showtimes: [],
                selectedDate: ''
            }
        },
        mounted() {
            this.getShowtimes();
        },
        methods: {
            getShowtimes: function() {
                let baseUrl = '/actions/moviemanager/performances/get-movie-performances';
                let url = baseUrl + '?movieId=' + this.movieId + '&locationId=' + this.locationId;
                // console.log(url);
                axios
                    .get(url)
                    .then(
                        (response) => {
                            // console.log(response);
                            this.showtimes = response.data;
                            this.selectedDate = response.data[0].date;
                        }
                    )
                    .catch(error => console.log(error))
            },
            filterByDate: function(showtime) {
                return showtime.date == this.selectedDate;
            },
            formatShowtime: function(showtime) {
                // console.log(showtime);
                var timeDisplay = moment(showtime.datetime).format('h:mm a');
                var reservedIcon = '<i class="reserved fak fa-recliner"></i>';
                var gtReservedAuditoriumIds = [1,2,3,4,5,6,7,8,9,10];
                var wfReservedAuditoriumIds = [8];
                var linkText = timeDisplay;
                if(this.locationId == 4 && gtReservedAuditoriumIds.includes(showtime.auditorium)) { //Georgetown recliner icons
                    linkText = timeDisplay + ' ' + reservedIcon;
                }
                if(this.locationId == 3 && wfReservedAuditoriumIds.includes(showtime.auditorium)) { //Weatherford recliner Icons
                    linkText = timeDisplay + ' ' + reservedIcon;
                }
                if(showtime.is3D) {
                    linkText = timeDisplay + ' ' + '3D';
                }
                if(showtime.isST) {
                    linkText = timeDisplay + ' ' + '<img src="/img/ST-red.png" title="ST-Immersive" />';
                }
                
                return linkText;
            },
            formatShowdate: function(showdate) {
                return moment(showdate).format('dddd MMM D');
            },
            nextDate: function() {
                this.selectedDate = moment(this.selectedDate).add(1, 'days').format('YYYY-MM-DD');
            },
            prevDate: function() {
                this.selectedDate = moment(this.selectedDate).subtract(1, 'days').format('YYYY-MM-DD');
            },
            
        },
        computed: {
            showtimesToDisplay() {
                return this.showtimes.filter(this.filterByDate);
            },
            showNextArrow() {
                let nextDate = moment(this.selectedDate).add(1, 'days').format('YYYY-MM-DD');
                let shows = this.showtimes.filter(showtime => showtime.date == nextDate);

                return shows.length ? true : false;
            },
            showPrevArrow() {
                let yesterday = moment().subtract(1, 'days').format('YYYY-MM-DD');
                let prevDate = moment(this.selectedDate).subtract(1, 'days').format('YYYY-MM-DD');
                let show = true;
                let shows = this.showtimes.filter(showtime => showtime.date == prevDate);
                if(!this.selectedDate || prevDate == yesterday) {
                    show = false;
                } else if(shows.length) {
                    show = true;
                }
                return show;
            }
        }
    }
</script>

<style>
    
</style>